import React, { Suspense, useState } from "react";
import background from "./assets/header.webp";
import logo from "./assets/kbrial logo.webp";
import tech from "./assets/tech.webp";
import graphic from "./assets/graphic.jpg";
import back from "./assets/back.mp4";
import people from "./assets/people.mp4";
import correo from "./assets/correo.png";
import llamar from "./assets/llamar.png";
import mapas from "./assets/mapas.png";
import instagram from "./assets/instagram.png";
import facebook from "./assets/facebook.png";
import { Formik } from "formik";
import * as Yup from "yup";

const GraphicSolutions = React.lazy(() =>
  import("./components/GraphicSolutions")
);

const TechSolutions = React.lazy(() => import("./components/TechSolutions"));

function App() {
  const [solutions, setSolutions] = useState("");
  const onSubmit = async (
    { email, name, message },
    { setSubmitting, setErrors, resetForm }
  ) => {
    const emailjs = await import("@emailjs/browser");
    try {
      setSubmitting(true);
      await emailjs.send(
        "service_ldi3wjd",
        "template_cky0gr6",
        { to_email: email, to_name: name },
        "uyG2vnrDp969ySkXe"
      );

      await emailjs.send(
        "service_ldi3wjd",
        "template_noibe8r",
        { to_email: email, to_name: name, message: message },
        "uyG2vnrDp969ySkXe"
      );

      alert("Mensaje enviado correctamente");

      resetForm();
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nombre es requerido"),
    email: Yup.string()
      .email("Correo invalido")
      .required("Correo es requerido"),
  });

  return (
    <div>
      <div
        className="sm:w-full  w-full h-screen bg-cover bg-center flex justify-center items-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="w-full h-full bg-black bg-opacity-50 text-white flex">
          <div className="w-full flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center w-1/2 mr-5">
              <img src={logo} alt="logo" className="w-96" width={384} />
              <div className="mt-10 flex flex-col justify-center items-center">
                <h1 className="text-5xl font-semibold text-center">
                  Descubre Soluciones Tecnológicas Innovadoras
                </h1>
                <h2 className="text-xl mt-5 text-center">
                  En Kabrial Studio, desarrollamos soluciones tecnológicas y
                  gráficas a la medida para impulsar tu negocio al siguiente
                  nivel.
                </h2>

                <a
                  href="#contacto"
                  className="bg-[#4849E8] px-12 py-3 rounded-lg mt-5 hover:bg-[#3c3cc1] transition-all duration-300"
                >
                  Contactanos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full overflow-hidden my-10">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={back}
          autoPlay
          loop
          muted
          disablePictureInPicture
          controls={false}
          aria-disabled
        />

        <div className="w-full h-[30rem] max-sm:h-screen bg-black bg-opacity-100 flex justify-center items-center">
          <div className="relative z-10 flex flex-col items-center justify-center h-full w-3/4">
            <h2 className="text-white text-6xl ">Nuestros</h2>
            <h2 className="text-[#4849E8] text-6xl ">Servicios</h2>
            <p className="text-white text-xl mt-5 text-center">
              En Kabrial Studio, nos especializamos en proporcionar soluciones
              tecnológicas y gráficas personalizadas que se adaptan a las
              necesidades específicas de tu negocio. Nuestro enfoque integral
              combina innovación y creatividad, garantizando que cada proyecto
              no solo cumpla con tus expectativas, sino que las supere.
            </p>
          </div>
        </div>
      </div>

      <div className="flex max-sm:flex-col">
        <div
          className="w-1/2 h-[30rem] max-sm:h-1/2 bg-cover bg-center max-sm:w-full "
          style={{ backgroundImage: `url(${graphic})` }}
        >
          <div className="w-full h-[30rem] max-sm:h-1/2 max-sm:pb-8 bg-black opacity-70 bg-opacity-70 hover:bg-opacity-80 hover:opacity-100 transition-all duration-300">
            <div>
              <div className="ml-10 pt-5 ">
                <h2 className="text-white text-6xl opacity-70">Soluciones</h2>
                <h3 className="text-[#FC00C2] text-6xl opacity-70 mt-7">
                  Gráficas
                </h3>
                <p className="text-white w-3/4 mt-5 text-lg">
                  Desde el diseño de identidades visuales hasta la creación de
                  interfaces intuitivas, nuestras soluciones gráficas están
                  diseñadas para captar la atención y comunicar la esencia de tu
                  marca. Creamos experiencias visuales impactantes que
                  fortalecen la presencia de tu negocio en el mercado.
                </p>
                <button
                  onClick={() => {
                    setSolutions("graphic");
                  }}
                  className="bg-[#4849E8] text-white px-7 py-1 rounded-lg mt-5"
                >
                  Saber mas
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-1/2 h-[30rem] max-sm:w-full max-sm:h-1/2 bg-black bg-opacity-50 hover:bg-opacity-30 transition-all duration-300 bg-cover bg-center"
          style={{ backgroundImage: `url(${tech})` }}
        >
          <div className="w-full h-[30rem] max-sm:h-1/2 max-sm:pb-8 bg-black opacity-70 bg-opacity-70 hover:bg-opacity-80 hover:opacity-100 transition-all duration-300">
            <div>
              <div className="ml-10 pt-5 ">
                <h2 className="text-white text-6xl">Soluciones</h2>
                <h3 className="text-[#D0F344] text-6xl mt-7">Tecnológicas</h3>
                <p className="text-white w-3/4 mt-5 text-lg">
                  Desarrollamos soluciones tecnológicas a medida que optimizan
                  procesos, mejoran la eficiencia y potencian el crecimiento de
                  tu empresa. Desde el desarrollo de software hasta la
                  integración de sistemas, nuestro enfoque está en crear
                  herramientas tecnológicas que se adapten a tus necesidades
                  específicas.
                </p>
                <button
                  onClick={() => {
                    setSolutions("tech");
                  }}
                  className="bg-[#4849E8] text-white px-7 py-1 rounded-lg mt-5"
                >
                  Saber mas
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        {solutions === "graphic" && <GraphicSolutions />}
        {solutions === "tech" && <TechSolutions />}
      </Suspense>

      <div className="relative w-full overflow-hidden my-10">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={people}
          autoPlay
          loop
          muted
          disablePictureInPicture
          controls={false}
          aria-disabled
        />
        <div className="relative w-full h-[30rem] max-sm:justify-center max-sm:items-center max-sm:h-screen bg-black bg-opacity-50 flex justify-center items-center">
          <div className=" w-3/4 flex flex-col items-center justify-center">
            <h2 className="text-white text-6xl">Nuestro</h2>
            <h2 className="text-[#4849E8] text-6xl">Equipo</h2>
            <p className="text-white text-xl text-center mt-5">
              En Kabrial Studio, contamos con un equipo multidisciplinario de
              profesionales apasionados por la tecnología y la creatividad.
              Nuestro equipo está conformado por diseñadores, desarrolladores y
              especialistas en marketing digital que trabajan en conjunto para
              ofrecerte soluciones innovadoras y de alta calidad. Nos
              comprometemos a brindarte un servicio personalizado y a superar
              tus expectativas en cada proyecto.
            </p>
          </div>
        </div>
      </div>

      <div
        id="contacto"
        className="flex items-center justify-center mb-12 max-sm:flex-col"
      >
        <div className="w-[65%] max-sm:w-full flex flex-col justify-center items-center ">
          <h3 className=" text-5xl font-semibold">Contáctanos</h3>
          <p className=" text-xl text-justify mt-10 mx-24 max-sm:mx-10">
            ¿Listo para llevar tu negocio al siguiente nivel? ¡Contáctanos hoy
            mismo y descubre cómo nuestras soluciones tecnológicas y gráficas
            pueden potenciar tu empresa!
          </p>
          <div className="w-full h-64 mb-10">
            <div className="flex justify-center items-center h-1/3 w-full mt-3">
              <img
                src={llamar}
                alt="call"
                className="bg-[#FC00C2] p-2 rounded-full mr-10"
                width={40}
              />
              <div className="flex flex-col justify-center w-48 ">
                <p className="text-xl font-semibold">Número de telefono</p>
                <p className="text-sm">44 36 85 60 90</p>
              </div>
            </div>
            <div className="flex justify-center items-center h-1/3 w-full">
              <img
                src={instagram}
                alt="instagram"
                className="bg-[#FC00C2] p-2 rounded-lg mr-10"
                width={40}
              />
              <div className="flex flex-col justify-center w-48 ">
                <p className="text-xl font-semibold">Instagram</p>
                <a
                  href="https://www.instagram.com/kabrialstudio/"
                  className="text-sm"
                >
                  @kabrialstudio
                </a>
              </div>
            </div>
            <div className="flex justify-center items-center h-1/3 w-full">
              <img
                src={facebook}
                alt="facebook"
                className="bg-[#FC00C2] p-2 rounded-full mr-10"
                width={40}
              />
              <div className="flex flex-col justify-center w-48 ">
                <p className="text-xl font-semibold">Facebook</p>
                <a
                  href="https://www.facebook.com/people/Kabrial-Studio/61564466943397/"
                  className="text-sm"
                >
                  Kabrial Studio
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[35%] h-[30rem] max-sm:mr-0 max-sm:w-full bg-[#4849E8] rounded-lg shadow-lg shadow-slate-400 mr-14">
          <h3 className="text-white font-semibold text-4xl mb-5 text-center mt-5">
            Envíanos un mensaje
          </h3>
          <div className="">
            <Formik
              onSubmit={onSubmit}
              initialValues={{ name: "", email: "", message: "" }}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center justify-center w-full"
                >
                  <input
                    type="text"
                    name="name"
                    maxLength={50}
                    placeholder="Nombre"
                    autoComplete="off"
                    className="bg-white px-3 py-2 rounded-lg mt-5 w-[80%] shadow-lg "
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="mt-5 text-white">
                    {errors.name && touched.name && errors.name}
                  </p>

                  <input
                    type="email"
                    name="email"
                    maxLength={50}
                    autoComplete="off"
                    placeholder="Correo"
                    className="bg-white px-3 py-2 mb-5 rounded-lg mt-5 w-[80%] shadow-lg"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className="text-white">
                    {errors.email && touched.email && errors.email}
                  </p>
                  <textarea
                    name="message"
                    autoComplete="off"
                    placeholder="Mensaje"
                    className="bg-white px-3 py-1 rounded-lg mt-5 resize-none w-[80%] h-32 shadow-lg"
                    maxLength={200}
                    value={values.message}
                    onChange={handleChange}
                  />
                  <p>{errors.message && touched.message && errors.message}</p>
                  <button
                    className="bg-[#FC00C2] mb-5 text-white px-5 py-2 rounded-lg mt-5 hover:bg-[#D0006F] transition-all duration-300 disabled:opacity-70 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Contactar
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="w-full h-48 bg-black flex items-center px-10 justify-between max-sm:flex-col max-sm:h-full max-sm:items-start">
        <div className="max-sm:flex max-sm:items-center max-sm:justify-center max-sm:w-full max-sm:mb-5 max-sm:mt-5">
          <img src={logo} alt="kabrial" className="w-56" />
        </div>
        <div className="flex items-center max-sm:mb-5">
          <img
            src={mapas}
            alt="map"
            className="bg-[#FC00C2] p-2 rounded-full mr-5"
            width={40}
          />
          <div>
            <h5 className="text-white">Domicilio</h5>
            <p className="text-white">
              Almendra 75, Lomas de la Huerta, <br /> 58050 Morelia, Mich.
            </p>
          </div>
        </div>
        <div className="flex items-center max-sm:mb-5">
          <img
            src={correo}
            alt="mail"
            className="bg-[#FC00C2] p-2 rounded-full mr-5"
            width={40}
          />
          <div>
            <h5 className="text-white">Correo</h5>
            <p className="text-white">contacto@kabrial.com</p>
          </div>
        </div>
        <div className="mb-10">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.887203335881!2d-101.21412158926938!3d19.67481708158054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0c33f4126357%3A0xc68aeafc91486b8d!2sAlmendra%2075%2C%20Lomas%20de%20la%20Huerta%2C%2058050%20Morelia%2C%20Mich.!5e0!3m2!1ses-419!2smx!4v1729642212930!5m2!1ses-419!2smx"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div>
        <div className="w-full bg-[#D0F344] flex items-center justify-center">
          <p className="font-medium text-center">
            Kabrial © Copyright 2024. All Right Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
